import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import classNames from 'classnames'
import {addCircularTabFocus, focusElement, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {ERROR} from '../../../../../actions/seating'
import {classes} from '../error-modal.st.css'
import {ButtonStyle} from '../../../common/button-style'
import {SomethingWentWrongProps} from './interfaces'

export const SomethingWentWrong = ({setError, tryAgainClick}: SomethingWentWrongProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  React.useEffect(() => {
    focusElement({selector: hookToAttributeSelector(DH.MODAL_TITLE)})
    addCircularTabFocus(hookToAttributeSelector(DH.CLOSE_BUTTON), hookToAttributeSelector(DH.TRY_AGAIN_BUTTON))
  }, [])

  return (
    <div className={classes.container}>
      <div className={classNames(classes.content, {[classes.desktopContent]: !isMobile})}>
        <div className={classes.text}>
          <Text
            data-hook={DH.MODAL_TITLE}
            tagName="h2"
            className={classNames(classes.title, {[classes.mobileTitle]: isMobile})}
          >
            {t('seatings_somethingWentWrongModal_title')}
          </Text>
        </div>
        <div className={classes.buttonContainer}>
          <ButtonStyle
            text={t('seatings_somethingWentWrongModal_closeButton')}
            onClick={() => setError(ERROR.NO_ERROR)}
            fullWidth={false}
            secondary={true}
            dataHook={DH.CLOSE_BUTTON}
          />
          <ButtonStyle
            text={t('seatings_somethingWentWrongModal_tryAgainButton')}
            onClick={() => tryAgainClick()}
            fullWidth={false}
            dataHook={DH.TRY_AGAIN_BUTTON}
          />
        </div>
      </div>
    </div>
  )
}
