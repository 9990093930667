import * as React from 'react'
import {PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import {TicketsListContainer} from '../../tickets-list-container'
import {Ticket} from '../../ticket'
import {ERROR} from '../../../../../actions/seating'
import {EmptyState} from './empty-state'
import s from './tickets-list.scss'
import {TicketsListProps} from '.'

export const TicketsList = ({
  places,
  addPlaceToBasket,
  setError,
  ticketLimitReached,
  setForcedPopover,
  selectPrice,
  selectLocation,
}: TicketsListProps) => {
  const placesCount = places.length

  const handlePlaceClick = (clickedPlace: PlaceWithTicketInfo, selected: boolean) => {
    if (ticketLimitReached && !selected) {
      setError(ERROR.TICKET_LIMIT_REACHED)
    } else {
      addPlaceToBasket({placeId: clickedPlace.id, count: selected ? 0 : 1})
    }
  }

  const handleEmptyStateButtonClick = () => {
    selectPrice(null)
    selectLocation(null)
  }

  return (
    <TicketsListContainer className={!placesCount ? s.emptyState : undefined}>
      {placesCount ? (
        places.map((place, index) => (
          <Ticket
            key={`${index}-${place.id}`}
            lastTicket={index === placesCount - 1}
            place={place}
            onClick={clickedPlace => handlePlaceClick(clickedPlace, place.inBasket)}
            selected={place.inBasket}
            setForcedPopover={setForcedPopover}
          />
        ))
      ) : (
        <EmptyState onButtonClick={handleEmptyStateButtonClick} />
      )}
    </TicketsListContainer>
  )
}
