import * as React from 'react'
import Plus from 'wix-ui-icons-common/on-stage/Plus'
import Minus from 'wix-ui-icons-common/on-stage/Minus'
import {IconButton} from 'wix-ui-tpa/IconButton'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {classes} from './controls.st.css'
import {ControlsProps} from '.'

export const Controls = ({zoomOutDisabled, zoomInDisabled, onZoomIn, onZoomOut}: ControlsProps) => {
  const {isMobile} = useEnvironment()

  return (
    <div className={classNames(classes.controls, {[classes.desktopControls]: !isMobile})}>
      <IconButton
        className={classes.control}
        disabled={zoomInDisabled}
        onClick={zoomInDisabled ? undefined : onZoomIn}
        icon={<Plus />}
      />
      <IconButton
        className={classes.control}
        disabled={zoomOutDisabled}
        onClick={zoomOutDisabled ? undefined : onZoomOut}
        icon={<Minus />}
      />
    </div>
  )
}
