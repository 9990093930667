import React, {useCallback, useEffect} from 'react'
import {Button} from 'wix-ui-tpa/Button'
import {Counter} from 'wix-ui-tpa/Counter'
import {IconButton} from 'wix-ui-tpa/IconButton'
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall'
import DeleteSmall from 'wix-ui-icons-common/on-stage/DeleteSmall'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Type} from '@wix/ambassador-seating-v1-seating-plan/build/es/types'
import SelectFill from 'wix-ui-icons-common/on-stage/SelectFill'
import classNames from 'classnames'
import {ERROR} from '../../../../actions/seating'
import {useColors} from '../../../../hooks/colors'
import {classes} from './ticket-info.st.css'
import {TicketInfoTexts} from './ticket-info-texts'
import {CustomDatesLabel} from './custom-dates-label'
import {TicketInfoProps} from '.'

export const TicketInfo = ({
  place,
  selected,
  showAccessibilityMode,
  onAddClick,
  onCloseClick,
  onRemoveClick,
  selectedTicketQuantity = 0,
  addPlaceToBasket,
  setError,
  inPopover,
  scheduled,
  inBasket = false,
  selectedTicketsQuantity,
  eventTicketLimitPerOrder,
  chooseSeatMode,
}: TicketInfoProps) => {
  const {t} = useTranslation()
  const [amount, setAmount] = React.useState(showAccessibilityMode ? selectedTicketQuantity : 1)
  const {darkColor} = useColors()
  const showCustomDatesLabel = onAddClick || showAccessibilityMode || inPopover

  useEffect(() => {
    setAmount(showAccessibilityMode || selectedTicketQuantity ? selectedTicketQuantity : 1)
  }, [selectedTicketQuantity])

  const {elementType, capacity} = place
  const area = elementType === Type.AREA
  const showCounter = !inBasket && !inPopover && area && (onAddClick || showAccessibilityMode) && !scheduled
  const min = showAccessibilityMode ? 0 : 1
  const totalSelectedTicketsQuantity =
    showAccessibilityMode && selectedTicketQuantity
      ? selectedTicketsQuantity - selectedTicketQuantity
      : selectedTicketsQuantity
  const max = chooseSeatMode ? 1 : Math.min(capacity, eventTicketLimitPerOrder - totalSelectedTicketsQuantity)

  const handleCounterChange = useCallback(
    (value: string) => {
      const newValue = Number(value)

      if (newValue > max && !chooseSeatMode) {
        setError(ERROR.TICKET_LIMIT_REACHED)
      } else {
        if (showAccessibilityMode || chooseSeatMode) {
          addPlaceToBasket({placeId: place.id, count: newValue})
        }
        setAmount(newValue)
      }
    },
    [max, min, setError, showAccessibilityMode, addPlaceToBasket, place.id],
  )

  return (
    <div className={classes.container} aria-hidden={true}>
      {selected ? (
        <SelectFill
          className={classNames(classes.checkbox, {
            [classes.checkboxFillWired]: darkColor,
            [classes.checkboxFillNotWired]: !darkColor,
          })}
        />
      ) : null}
      {onCloseClick ? (
        <IconButton className={classes.headerButton} icon={<CloseSmall />} onClick={onCloseClick} />
      ) : null}
      {onRemoveClick ? (
        <IconButton
          data-hook={DH.REMOVE_TICKET}
          className={classes.headerButton}
          icon={<DeleteSmall />}
          onClick={onRemoveClick}
        />
      ) : null}
      {showCustomDatesLabel && <CustomDatesLabel place={place} />}
      <TicketInfoTexts place={place} />
      {showCounter && (
        <Counter
          className={classes.counter}
          incrementAriaLabel={t('seatings_area_counter_increment')}
          decrementAriaLabel={t('seatings_area_counter_decrement')}
          inputAriaLabel={t('seatings_area_counter_input')}
          errorTooltipMaxWidth={300}
          value={amount}
          min={min}
          max={capacity}
          onChange={handleCounterChange}
        />
      )}
      {onAddClick && !scheduled ? (
        <Button className={classes.addButton} data-hook={DH.ADD} onClick={() => onAddClick(amount)}>
          {t('seatings_add')}
        </Button>
      ) : null}
    </div>
  )
}
